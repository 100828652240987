import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './utils/Auth';
import './index.css';

// Route components are wrapped in lazy loading to improve performance
const Navigation = lazy(() => import('./components/Navigation'));
const VCard = lazy(() => import('./pages/VCard'));
const Login = lazy(() => import('./pages/Login'));
const Settings = lazy(() => import('./pages/Settings'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Home = lazy(() => import('./components/Home'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const SignUp = lazy(() => import('./pages/SignUp'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Admin = lazy(() => import('./pages/Admin'));
const Action = lazy(() => import('./pages/Action'));
const Inbox = lazy(() => import('./pages/Inbox'));
const Upgrade = lazy(() => import('./pages/Upgrade'));
const AccountSettings = lazy(() => import('./pages/AccountSettings'));
const AdminRoute = lazy(() => import('./components/PrivateRoutes/AdminRoute'));
const Contacts = lazy(() => import('./pages/Contacts'));
const EnterpriseAdmin = lazy(() => import('./pages/EnterpriseAdmin'));
const EnterpriseAdminRoute = lazy(() => import('./components/PrivateRoutes/EnterpriseAdminRoute'));
const Wizard = lazy(() => import('./pages/Wizard'));
const Events = lazy(() => import('./pages/Events'));
const EventsDashboard = lazy(() => import('./pages/EventsDashboard'));
const CreateEnterprise = lazy(() => import('./pages/CreateEnterprise'));
const ManageRouter = lazy(() => import('./pages/ManageRouter'));
const GeneratorSignUp = lazy(() => import('./pages/GeneratorSignUp'));
const Authentication = lazy(() => import('./pages/Authenticate'));
const ProtectedRoute = lazy(() => import('./components/PrivateRoutes/ProtectedRoute'));

const App = () => {
    window.addEventListener('load', () => {
        // setTimeout is needed for iPhones
        setTimeout(function () {
            // hides address bars
            window.scrollTo(0, 1);
        }, 0);
    });

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            const script = document.createElement('script');

            script.src = "https://tools.luckyorange.com/core/lo.js?site-id=11940272";
            script.async = true;
            script.defer = true;

            document.body.appendChild(script);
        }
    }, []);


    return (
        <Suspense fallback={<div className='suspense-loader-wrap'><div className='settings-loader'></div></div>}>
            <Router>
                <AuthProvider>
                    <Navigation />
                    <Switch>
                        {(process.env.NODE_ENV === 'production' && window.location.hostname.includes('bitsignal.io'))
                            ?
                            <Route exact path='/' component={() => {
                                window.location.replace('https://mybitsignal.com');
                                return null;
                            }} />
                            :
                            <Route exact path='/' component={Home} />
                        }
                        <Route exact path='/login' component={Login} />
                        <Route exact path='/settings/:path?/:path?' component={Settings} />
                        <Route exact path='/reset' component={PasswordReset} />
                        <Route exact path='/action' component={Action} />
                        <Route exact path='/faq' component={FAQ} />
                        <Route exact path='/signup' component={SignUp} />
                        <Route exact path='/signup/wizard' component={Wizard} />
                        <ProtectedRoute exact path='/inbox' component={Inbox} reference='inbox' />
                        <Route exact path='/contacts' component={Contacts} />
                        <Route exact path='/upgrade' component={Upgrade} />
                        <Route exact path='/accountsettings' component={AccountSettings} />
                        <Route exact path='/teams/:path?' component={CreateEnterprise} />
                        <AdminRoute exact path='/admin' component={Admin} />
                        <EnterpriseAdminRoute exact path='/dashboard/:path?/:path?/:path?' component={EnterpriseAdmin} />
                        <Route exact path='/e/:eventId/v/:vendorId' component={Events} />
                        <Route exact path='/e/:eventId/:badgeId' component={Events} />
                        <Route exact path='/events/:event?/:id?' component={EventsDashboard} />
                        <Route exact path='/notfound' component={NotFound} />
                        <Route exact path='/manage/:teams?' component={ManageRouter} />
                        <Route exact path='/claim' component={GeneratorSignUp} />
                        <Route exact path='/authenticate' component={Authentication} /> 
                        <Route exact path='/:enterprise?/:username' component={VCard} />
                        <Route component={NotFound} />
                    </Switch>
                </AuthProvider>
            </Router>
        </Suspense>
    );
};

export default App;