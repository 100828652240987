export function createVCF(username, vcard, pic) {
    return new Promise ((resolve, reject) => {
        const ua = navigator.userAgent;
        let version;
        if (/windows phone/.test(ua)) {
            version = '2.1';
        } else if (/android/.test(ua)) {
            version = '2.1';
        } else if (/iPad|iPhone|iPod/.test(ua)) {
            version = '3.0';
        } else if (/Mac/.test(ua)) {
            version = '3.0';
        } else if (/Windows/.test(ua)) {
            version = '2.1';
        } else {
            version = '2.1';
        };
    
        let buttons = vcard.buttons;
        let socials = [];
        let details = [];
        let fullDetails;
        let fullSocials;
        let vcffile;
    
        vcard?.job_title && details.push(`TITLE:${vcard?.job_title}`);
        vcard?.organization_name && details.push(`ORG:${vcard.organization_name}`);
    
        if (vcard?.address) {
            details.push(`ADR:;;${vcard?.address};`)
        };
    
        if (pic !== '') {
            details.push(`PHOTO;ENCODING=BASE64:${pic}`);
        };
    
        if (buttons) {
            for (let btn in buttons){
                if (!buttons[btn].data.includes('http') && !['phone', 'email'].includes(buttons[btn].ref)) {
                    buttons[btn].data = `https://${buttons[btn].data}`;
                };
    
                if (version === '2.1') {
                    switch (buttons[btn].ref){
                        case 'facebook':
                            socials.push(`URL;TYPE=Facebook:${buttons[btn].data}`);
                            break;
                        case 'instagram':
                            socials.push(`URL;TYPE=Instagram:${buttons[btn].data}`);
                            break;
                        case 'linkedin':
                            socials.push(`URL;TYPE=LinkedIn:${buttons[btn].data}`);
                            break;
                        case 'pinterest':
                            socials.push(`URL;TYPE=Pinterest:${buttons[btn].data}`);
                            break;
                        case 'tumblr':
                            socials.push(`URL;TYPE=Tumblr:${buttons[btn].data}`);
                            break;
                        case 'twitter':
                            socials.push(`URL;TYPE=Twitter:${buttons[btn].data}`);
                            break;
                        case 'whatsapp':
                            socials.push(`URL;TYPE=WhatsApp:${buttons[btn].data}`);
                            break;
                        case 'youtube':
                            socials.push(`URL;TYPE=YouTube:${buttons[btn].data}`);
                            break;
                        case 'phone':
                            details.push(`TEL;TYPE=WORK:${buttons[btn].data}`);
                            break;
                        case 'email':
                            details.push(`EMAIL;TYPE=WORK:${buttons[btn].data}`);
                            break;
                        case 'website':
                            details.push(`URL;TYPE=${buttons[btn].ref}:${buttons[btn].data}`);
                            break;
                        default:
                            // console.log("No socials");
                            break;
                    };
                } else {
                    switch (buttons[btn].ref){
                        case 'facebook':
                            socials.push(`X-SOCIALPROFILE;TYPE=Facebook;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'instagram':
                            socials.push(`X-SOCIALPROFILE;TYPE=Instagram;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'linkedin':
                            socials.push(`X-SOCIALPROFILE;TYPE=Linkedin;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'pinterest':
                            socials.push(`X-SOCIALPROFILE;TYPE=Pinterest;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'tumblr':
                            socials.push(`X-SOCIALPROFILE;TYPE=Tumblr;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'twitter':
                            socials.push(`X-SOCIALPROFILE;TYPE=Twitter;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'whatsapp':
                            socials.push(`X-SOCIALPROFILE;TYPE=Whatsapp;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'youtube':
                            socials.push(`X-SOCIALPROFILE;TYPE=Youtube;x-user=${buttons[btn].data.substring(buttons[btn].data.lastIndexOf('/') +1)}:${buttons[btn].data}`);
                            break;
                        case 'phone':
                            details.push(`TEL;TYPE=${(buttons[btn].label === "" || buttons[btn].label === undefined) ? "Phone" : buttons[btn].label}:${buttons[btn].data}`);
                            break;
                        case 'email':
                            details.push(`EMAIL;TYPE=${(buttons[btn].label === "" || buttons[btn].label === undefined) ?  "Email" : buttons[btn].label}:${buttons[btn].data}`);
                            break;
                        case 'website':
                            details.push(`URL;TYPE=${(buttons[btn].label === "" || buttons[btn].label === undefined) ?  "Website" : buttons[btn].label}:${buttons[btn].data}`);
                            break;
                        default:
                            // console.log("No socials");
                            break;
                    };
                };
            };
      
        };
        fullDetails = details.join('\n');
        fullSocials = socials.join('\n');
    
        if(socials === undefined){
            vcffile = new File(
    [
`BEGIN:VCARD
VERSION:3.0
N:${vcard?.name_last};${vcard?.name_first};;;
FN:${vcard?.name_first} ${vcard?.name_last}
${fullDetails}
END:VCARD
`
], `${username}.vcf`,
            { type: 'text/vcard'}
        );
    } else {
        vcffile = new File(
            [
`BEGIN:VCARD
VERSION:3.0
N:${vcard?.name_last};${vcard?.name_first};;;
FN:${vcard?.name_first} ${vcard?.name_last}
${fullDetails}
${fullSocials}
END:VCARD
`
        ], `${username}.vcf`,
                    { type: 'text/vcard'}
                );
            }
        if (vcffile) {
            resolve(vcffile);
        } else {
            reject("No file created");
        }
    });
};